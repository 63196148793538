<script>
import { useStore } from 'vuex'
import { onMounted } from 'vue'

import ButtonAnswersTemplate from "@/components/shared/base-components/ButtonAnswersTemplate"

const stepName = 'IsMedicalConditionListed'

const medicalConditions = [
  'Aids',
  'Brain Tumor',
  'Central nervous disorder',
  'Chronic Kidney Disease',
  'Cirrhosis',
  'Dementia',
  'Hepatitis C',
  'Lupus',
  'Stroke',
  'Schizophrenia',
  'Suicide attempts',
  'Terminal illness'
]

const ANSWERS = [
  'Yes',
  'No'
]

const questionText = 'Is your other medical condition any of the following?'

export default {
  name: stepName,

  emits: ['next-step'],

  components: {
    ButtonAnswersTemplate
  },

  setup(_, {emit}) {
    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })
    const store = useStore()

    const setAnswer = (userInput) => {
      store.commit('SET_FIELD', {field: 'is_medical_condition_listed', value: userInput.toLowerCase()})
      emit('next-step', stepName)
    }

    return {
      medicalConditions,
      questionText,
      ANSWERS,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <button-answers-template
      :button-action="setAnswer"
      :answers="ANSWERS"
      :question-text="questionText"
    >
      <div class="row">
        <div class="box conditions-box">
          <div class="conditions-wrapper">
            <div class="inner-conditions-wrapper">
              <ul class="conditions-list">
                <li
                  v-for="condition in medicalConditions"
                  :key="condition"
                  class="condition-item"
                >
                  {{condition}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </button-answers-template>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/is_medical_condition_listed';
</style>